import React from "react";
const PaymentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M18.108 12.139c.379.515.269 1.238-.246 1.617l-3.663 2.7a3.939 3.939 0 0 1-2.335.767H2.592a.925.925 0 0 1-.926-.926v-1.852c0-.512.413-.926.926-.926h1.064l1.3-1.042a3.696 3.696 0 0 1 2.314-.81h4.58a.925.925 0 1 1 0 1.852H9.538a.464.464 0 0 0-.463.463c0 .255.208.463.463.463h3.49l3.463-2.552a1.156 1.156 0 0 1 1.618.246ZM6.667 6.667a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Zm3.412.242c.414.102.537.236.537.464 0 .236-.159.415-.537.444v-.908Zm-.249-.51c-.353-.089-.486-.236-.486-.438 0-.2.168-.384.486-.424V6.4Zm.25.06v-.922c.282.036.481.197.51.475h.46c-.018-.501-.418-.832-.97-.877v-.373h-.25v.373c-.557.042-.954.37-.954.864 0 .448.302.7.863.83l.091.023v.963c-.356-.038-.524-.228-.563-.486H8.81c.015.53.459.846 1.02.888v.37h.25v-.37c.563-.04 1.005-.347 1.005-.888 0-.47-.318-.71-.9-.844l-.106-.027Z"
    />
  </svg>
);
export default PaymentIcon;
