import React from "react";
const CustomerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M5.855 2.302a3.337 3.337 0 0 0-1.38 2.705 3.338 3.338 0 0 0 3.337 3.34 3.338 3.338 0 0 0 3.336-3.34 3.338 3.338 0 0 0-3.441-3.34c-.698 0-1.34.237-1.852.635Z"
    />
    <path
      fill={props.color ?? "#3A3A41"}
      fillRule="evenodd"
      d="M3.58 10.397c1.11-.625 2.607-.995 4.232-.995s3.122.37 4.231.995c.186.105.364.219.532.342h-.358a3 3 0 0 0-3 3v1.56c0 .518.131 1.006.363 1.431a22.703 22.703 0 0 1-1.591.056h-.177c-1.423 0-2.515-.089-3.353-.259-.835-.169-1.454-.425-1.896-.786-.898-.732-.897-1.758-.896-2.575v-.072c0-1.149.821-2.081 1.914-2.697Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? "#3A3A41"}
      fillRule="evenodd"
      d="M14.012 11.105a.755.755 0 0 1 1.12-.046l.041.046 1.486 1.783h1.128a.564.564 0 0 1 .56.625l-.034.29-.038.279-.031.21-.036.224-.04.236-.047.245-.051.25c-.028.126-.057.252-.088.377a7.934 7.934 0 0 1-.314.976l-.084.204-.08.185-.077.164-.036.073-.094.185a.73.73 0 0 1-.59.386l-.06.003h-4.112a.73.73 0 0 1-.646-.381l-.088-.17-.068-.14-.036-.078-.075-.17a8.12 8.12 0 0 1-.478-1.484l-.053-.245-.048-.24-.043-.231-.037-.222-.033-.21-.028-.193-.034-.258-.025-.21-.007-.058a.564.564 0 0 1 .51-.62l.051-.002h1.13l1.485-1.783Zm-.19 3.567a.377.377 0 0 0-.74.123l.004.044.19 1.133.009.043a.378.378 0 0 0 .74-.123l-.005-.044-.188-1.133-.01-.043Zm1.966-.268a.378.378 0 0 0-.425.268l-.01.043-.188 1.133a.378.378 0 0 0 .735.167l.01-.043.189-1.133a.378.378 0 0 0-.31-.435Zm-1.195-2.816-1.083 1.3h2.165l-1.082-1.3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CustomerIcon;
