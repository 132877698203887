import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const AccountColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params,
  hideColmn,
  hideBak
) => {
  const logData = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "CustomerNo",
      key: "CustomerNo",
      sorter: (a, b) => a?.CustomerNo - b?.CustomerNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemName",
      hidden: hideColmn,
      key: "OemName",
      sorter: (a, b) => a?.OemName?.length - b?.OemName?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerName",
      hidden: [7, 8, 9].includes(logData.role_value),
      key: "DealerName",
      sorter: (a, b) => a?.DealerName?.length - b?.DealerName?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "CustomerName",
      key: "CustomerName",
      sorter: (a, b) => a?.CustomerName?.length - b?.CustomerName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "CustomerDesc",
      key: "CustomerDesc",
      sorter: (a, b) => a?.CustomerDesc?.length - b?.CustomerDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },

    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) => (
        <div className="flex justify-center items-center">
          <div className="mr-1">
            <Link
              to={`/customer/edit/${record?.CustomerNo}`}
              onClick={(e) => e.stopPropagation()}
              className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] hover:text-gray-700  p-1 rounded-[4px]"
            >
              <EditOutlined />
            </Link>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
              setSelectedRowKeys([record?.CustomerNo]);
            }}
            className="hover:cursor-pointer"
          >
            <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
          </div>
        </div>
      ),
    },
  ];
};
