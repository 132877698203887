import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row, Modal } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { OrderInfo } from "../Page/OrderInfo";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import {
  CheckCircleOutlined,
  CloseOutlined,
  UnorderedListOutlined,
  DollarCircleOutlined,
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  ProductOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import OrderIcon from "../../../assets/images/vectors/SideBarIcons/OrderIcon";
import MyPaymentForm from "../../../global/components/PaymentForm";

export const OrderInfoForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paidStatus, setPaidStatus] = useState();

  const [form] = Form.useForm();
  const dealerNo = Form.useWatch("DealerNo", form);
  const { id } = useParams();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/order/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/order/create`;
  const emailUrl = `${process.env.REACT_APP_API_URL}/api/order/email`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/order/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/order/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const getOemUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getOemByDealer/${dealerNo}`;

  const { data, loading, error, refetch } = useGet(url);
  const { data: oemData } = useGet(getOemUrl);
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "dealers");
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const {
    data: emailData,
    loading: emailLoading,
    error: emailError,
    postData: sentEmail,
  } = usePost(emailUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const handleEmail = () => {
    if (id) {
      sentEmail({ OrderNo: Number(id) });
    }
  };

  const handleConfirm = () => {
    putAction({ Paid: 1 });
    setTimeout(() => {
      refetch();
    }, 1000);
    navigate(`/orderinfo/info/${id}`);
  };

  const onFinish = async (values) => {
    try {
      if (id) {
        const modifiedValues = {
          ...values,
          Amount: Number(data.data.Amount),
          Paid: Number(data.data.Paid),
          OrderTime: data.data.OrderTime,
        };
        await putAction(modifiedValues);
      } else {
        await postAction(values);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate(`/orderinfo/info/${id}`);
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  //toast for email
  useEffect(() => {
    if (emailError) {
      showToast(emailError?.response?.data?.message, "error", faWarning);
    } else if (!emailLoading && !emailError && emailData) {
      showToast(emailData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [emailError, emailData, emailLoading]);

  //toast for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toast for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    form.setFieldsValue({
      OemNo: oemData?.OemNo,
    });
  }, [modifiedAccounts]);

  useEffect(() => {
    if (!id || loading) return;

    const getOemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            DealerNo: data?.data?.DealerNo,
            OrderName: data.data.OrderName,
            OrderDesc: data.data.OrderDesc,
            Amount: String(data.data.Amount),
            Paid: Number(data.data.Paid),
            OrderTime: data.data.OrderTime
              ? formatUnixTime(data.data.OrderTime)
              : "",
          });
          data?.data?.Paid == 1 ? setPaidStatus(true) : setPaidStatus(false);
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOemInfoById();
  }, [id, data, loading, form]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
      navigate("/orderlist");
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Order",
      href: "/orderlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/orderinfo/info/${id}`
          : `/orderinfo/edit/${id}`
        : "/orderinfo/add",
    },
  ];

  let buttonsData;
  paidStatus
    ? (buttonsData = [
        {
          label: "Email",
          icon: <MailOutlined />,
          onClick: () => setShowEmailModal(true),
        },
        {
          label: "List",
          icon: <UnorderedListOutlined />,
          onClick: () => {
            navigate("/orderlist");
          },
        },
      ].filter(Boolean))
    : (buttonsData =
        type === "info"
          ? [
              [1, 2, 3, 7, 8, 9].includes(loggedInUser.role_value) && {
                label: "Confirm",
                icon: <DollarCircleOutlined />,
                onClick: () => {
                  setShowCommonModal(true);
                },
              },
              [1, 2, 3, 7, 8, 9].includes(loggedInUser.role_value) && {
                label: "Make Payment",
                icon: <DollarCircleOutlined />,
                onClick: () => {
                  setPaymentModal(true);
                },
              },
              {
                label: "Edit",
                icon: <EditOutlined />,
                onClick: () => {
                  navigate(`/orderinfo/edit/${id}`);
                },
              },
              {
                label: "Delete",
                icon: <DeleteOutlined />,
                onClick: () => {
                  setShowModal(true);
                },
              },
              {
                label: "List",
                icon: <UnorderedListOutlined />,
                onClick: () => {
                  navigate("/orderlist");
                },
              },
            ].filter(Boolean)
          : [
              {
                label: "Apply",
                icon: <CheckCircleOutlined />,
                onClick: () => form.submit(),
              },
              {
                label: "Cancel",
                icon: <CloseOutlined />,
                onClick: onReset,
              },
            ]);

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {!loading && (
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
          className=""
        >
          <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-[#EEEEEF] p-2">
              <OrderIcon />
              <span className="text-[16px] text-[#414141] font-semibold ml-2">
                Order
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 0]}>
                {![4, 5, 6, 7, 8, 9].includes(loggedInUser.role_value) && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="OEM"
                      name="OemNo"
                      options={modifiedOems}
                      placeholder="Please select"
                      disabled={true}
                    />
                  </Col>
                )}
                {![7, 8, 9].includes(loggedInUser.role_value) && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Dealer"
                      name="DealerNo"
                      options={modifiedAccounts}
                      placeholder="Please select"
                      disabled={data?.data?.Paid == 1 || type === "info"}
                    />
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Name"
                    name="OrderName"
                    disabled={data?.data?.Paid == 1 || type === "info"}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Description"
                    name="OrderDesc"
                    disabled={data?.data?.Paid == 1 || type === "info"}
                  />
                </Col>
                {type === "info" && (
                  <>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Amount"
                        name="Amount"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Confirmed"
                        name="Paid"
                        options={[
                          { label: "No", value: 0 },
                          { label: "Yes", value: 1 },
                        ]}
                        placeholder="Please select"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Order Time"
                        name="OrderTime"
                        disabled={true}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        </Form>
      )}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
      {type === "info" && (
        <div className="mt-4 border bg-white rounded-[4px]  p-2">
          <OrderInfo paidStatus={paidStatus} id={id} trigger={refetch} />
        </div>
      )}
      <ConfirmModal
        showModal={showCommonModal}
        setShowModal={setShowCommonModal}
        onDeleteConfirm={handleConfirm}
        content="Are you sure you want to proceed?"
      />
      <ConfirmModal
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        onDeleteConfirm={handleEmail}
        content="Are you sure you want to send Email?"
      />
      <Modal
        open={paymentModal}
        onCancel={() => setPaymentModal(false)}
        footer={null}
        width={1000}
        centered
      >
        <div className="my-6">
          <MyPaymentForm
            DealerNo={data?.data?.DealerNo}
            amount={data?.data?.Amount}
            setPaymentModal={setPaymentModal}
            OrderNo={id}
            OrderName={data?.data?.OrderName}
            refetch={refetch}
          />
        </div>
      </Modal>
    </div>
  );
};
