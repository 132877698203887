import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const DealerColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params,
  hideColmn
) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "DealerNo",
      key: "DealerNo",
      sorter: (a, b) => a?.DealerNo - b?.DealerNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      hidden: hideColmn,
      key: "OemNo",
      sorter: (a, b) => a?.OemNo - b?.OemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "DealerName",
      key: "DealerName",
      sorter: (a, b) => a?.DealerName?.length - b?.DealerName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "DealerDesc",
      key: "DealerDesc",
      sorter: (a, b) => a?.DealerDesc?.length - b?.DealerDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Billing Cycle",
      dataIndex: "BillingCycle",
      key: "BillingCycle",
      sorter: (a, b) => a?.BillingCycle?.length - b?.BillingCycle?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="BillingCycle"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "BillingCycle",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Auto Billing",
      dataIndex: "AutoBilling",
      key: "AutoBilling",
      sorter: (a, b) => a?.AutoBilling?.length - b?.AutoBilling?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AutoBilling"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AutoBilling",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) => (
        <div className="flex justify-center items-center">
          <div className="mr-1">
            <Link
              to={`/dealer/edit/${record?.DealerNo}`}
              onClick={(e) => e.stopPropagation()}
              className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] hover:text-gray-700  p-1 rounded-[4px]"
            >
              <EditOutlined />
            </Link>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
              setSelectedRowKeys([record?.DealerNo]);
            }}
            className="hover:cursor-pointer"
          >
            <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
          </div>
        </div>
      ),
    },
  ];
};
