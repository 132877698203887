import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Input, Row, Select } from "antd";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { DealerColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const initialFilterState = { DealerNo: "", OemNo: "", DealerName: "" };

export const Dealer = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const logData = JSON.parse(sessionStorage.getItem("userData"));

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [params, setParams] = useState({
    limit: 10,
    sort_by: "DealerNo",
    order: "desc",
  });

  const navigate = useNavigate();

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/dealer/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/dealer/all`;
  const { data, loading, error, refetch } = useGet(url);
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/dealer/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const modifiedOems = getModifiedResponse(optionData, "items");
  const modifiedBillingCycles = getModifiedResponse(optionData, "bill_cycles");
  const modifiedAutoBilling = getModifiedResponse(optionData, "auto_billings");

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/dealers/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/dealer/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // navigate(`/oemlist/${newSelectedRowKeys}`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/dealer/info/${record?.DealerNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "DealerNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
    setSelectedRowKeys([]);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.DealerNo,
      }));
      setListData(newData);
    }
    // setSelectedRowKeys([]);
  }, [data, loading]);

  const breadcrumbItems = [
    {
      label: "Dealer",
      href: "/dealerlist",
    },
  ];

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-[4px] border border-gray-200">
        {/* Filter part */}
        <div className="mb-4">
          <div className="mt-2">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  name="DealerNo"
                  placeholder="No"
                  value={filter?.DealerNo}
                  onChange={handleFilterChange}
                />
              </Col>
              {![4, 5, 6].includes(logData.role_value) && (
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <Select
                    showSearch
                    placeholder="OEM"
                    optionFilterProp="OemNo"
                    value={filter.OemNo || undefined}
                    onChange={(value) => setFilter({ ...filter, OemNo: value })}
                    // onSearch={onSearch}
                    options={modifiedOems}
                    style={{ width: "100%" }}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  placeholder="Name"
                  value={filter.DealerName}
                  onChange={handleFilterChange}
                  name="DealerName"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Select
                  showSearch
                  placeholder="Billing Cycle"
                  optionFilterProp="BillingCycle"
                  value={filter.BillingCycle || undefined}
                  onChange={(value) =>
                    setFilter({ ...filter, BillingCycle: value })
                  }
                  // onSearch={onSearch}
                  options={modifiedBillingCycles}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Select
                  showSearch
                  placeholder="Auto Billing"
                  optionFilterProp="AutoBilling"
                  value={filter.AutoBilling || undefined}
                  onChange={(value) =>
                    setFilter({ ...filter, AutoBilling: value })
                  }
                  // onSearch={onSearch}
                  options={modifiedAutoBilling}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>

          <div className="flex justify-between my-6 overflow-x-auto">
            <div>
              <Button
                type="primary"
                className="mr-2 w-[104px] h-[32px] font-medium"
                onClick={handleFilter}
              >
                Apply
              </Button>
              <Button className="reset-button" onClick={handleReset}>
                Reset
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                className="mr-2 w-[32px] h-[32px]"
                onClick={() => navigate("/dealer/add")}
              >
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                disabled={selectedRowKeys?.length === 0 ? true : false}
                className="w-[32px] h-[32px]"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          onDeleteConfirm={onDeleteConfirm}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={DealerColumns(
            hoveredRow,
            setShowModal,
            setSelectedRowKeys,
            setParams,
            params,
            logData?.role_value === 4 ||
              logData.role_value === 5 ||
              logData.role_value === 6
          )}
          dataSource={listData}
          rowSelection={rowSelection}
          pagination={paginationConfig}
          // onRow={onRow}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
        />
      </div>
    </div>
  );
};
