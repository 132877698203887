import { Col, Row } from "antd";
import * as React from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import usePost from "../hooks/usePost";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../utils/toast";
const MyPaymentForm = ({
  DealerNo,
  amount,
  setPaymentModal,
  OrderNo,
  OrderName,
  refetch,
}) => {
  const postUrl = `${process.env.REACT_APP_API_URL}/api/order/payment`;
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const handleSuccess = (token) => {
    const payload = {
      Source_id: token?.token,
      DealerNo,
      Amount: Number(amount),
      Currency: "USD",
      // OrderNo: Number(OrderNo),
    };
    postAction(payload);
    setPaymentModal(false);
  };

  React.useEffect(() => {
    refetch();
  }, [postData, postLoading]);

  const handleError = (errors) => {
    console.error("Payment failed:", errors);
  };
  React.useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
    }
  }, [postError, postData, postLoading]);

  return (
    <>
      <p className="text-xl mb-6">
        Please provide your card information to proceed with your order,{" "}
        <span className="font-bold">{OrderName}</span> The total bill for your
        purchase is <span className="font-bold">${amount}</span>
      </p>
      <PaymentForm
        applicationId={process.env.REACT_APP_APPLICATION_ID}
        locationId={process.env.REACT_APP_LOCATION_ID}
        cardTokenizeResponseReceived={(token, buyer) => {
          handleSuccess(token);
        }}
      >
        {/* Square's CreditCard Component for Tokenization */}
        <Row>
          <Col span={24}>
            <CreditCard
              buttonProps={{
                css: {
                  backgroundColor: "var(--text-color)",
                  fontSize: "14px",
                  padding: "8px 16px",
                  height: "36px",
                  width: "180px",
                  borderRadius: "4px",
                  display: "block",
                  margin: "0 auto",
                },
              }}
            />
          </Col>
        </Row>
      </PaymentForm>
    </>
  );
};

export default MyPaymentForm;
