import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  ProductOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import OrderIcon from "../../../assets/images/vectors/SideBarIcons/OrderIcon";

export const OrderForm = () => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));

  const [form] = Form.useForm();
  const dealerNo = Form.useWatch("DealerNo", form);
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/order/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/order/create`;
  const putUrl =
    id && `${process.env.REACT_APP_API_URL}/api/order/update/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const getOemUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getOemByDealer/${dealerNo}`;

  const { data, loading, error, refetch } = useGet(url);
  const { data: oemData } = useGet(getOemUrl);
  const { data: optionData } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "dealers");
  const modifiedOems = getModifiedResponse(optionData, "oems");

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = async (values) => {
    try {
      const isDealerRole =
        loggedInUser.role_value === 7 ||
        loggedInUser.role_value === 8 ||
        loggedInUser.role_value === 9;

      if (isDealerRole) {
        values = {
          ...values,
          DealerNo: loggedInUser.dealer_no,
        };
      }

      if (id) {
        await putAction(values);
      } else {
        await postAction(values);
      }
    } catch (error) {
      showToast("Something went wrong, please try again!", "error", faWarning);
      console.error(error); // Use console.error for error logging
    }
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(`/orderinfo/info/${postData?.data?.OrderNo}`);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  const onReset = () => {
    // form.resetFields();
    navigate("/orderlist");
  };

  // Set default field value
  useEffect(() => {
    if (!id && !form.getFieldValue("DealerNo")) {
      form.setFieldsValue({
        DealerNo: modifiedAccounts[0]?.value,
      });
    }
    form.setFieldsValue({
      OemNo: oemData?.OemNo,
    });
  }, [modifiedAccounts]);

  useEffect(() => {
    if (!id || loading) return;
    const getOemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            DealerNo: data?.data?.DealerNo,
            OrderName: data.data.OrderName,
            OrderDesc: data.data.OrderDesc,
            Amount: data.data.Amount,
            Paid: data.data.Paid,
            OrderTime: data.data.OrderTime,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOemInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Order",
      href: "/orderlist",
    },
    {
      label: "Add",
      href: "/order/add",
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/orderlist");
      },
    },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-[#EEEEEF] p-2">
              <OrderIcon />
              <span className="text-[16px] text-[#414141] font-semibold ml-2">
                Order
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 0]}>
                {![4, 5, 6, 7, 8, 9].includes(loggedInUser.role_value) && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="OEM"
                      name="OemNo"
                      options={modifiedOems}
                      placeholder="Please select"
                      disabled={true}
                    />
                  </Col>
                )}
                {![7, 8, 9].includes(loggedInUser.role_value) && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Dealer"
                      name="DealerNo"
                      options={modifiedAccounts}
                      placeholder="Please select"
                      required
                    />
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Name" name="OrderName" required />
                </Col>
                {/* </Row>
                <Row gutter={[16, 16]}> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Description" name="OrderDesc" />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      }
    </div>
  );
};
