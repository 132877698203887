import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, Modal } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";
import ProfileIcon from "../../assets/images/vectors/ProfileIcon";
import { ProfileForm } from "./ProfileForm";
import MyPaymentForm from "./PaymentForm";

export const User = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [formattedDateTime, setFormattedDateTime] = useState({});
  const [isModalOpen, setIsModalOpen] = useState();
  const [showCardModal, setShowCardModal] = useState(false);

  const handleMenuClick = (e) => {
    if (e.key === "profile") {
      setIsModalOpen(true);
      // navigate("/profile");
    } else if (e.key === "logout") {
      sessionStorage.removeItem("userData");
      showToast("Logged out successfully !", "success", faCheckCircle);
      navigate("/");
    } else if (e.key === "Info") {
      setShowCardModal(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {userData?.role_name != "Customer" && (
        <Menu.Item key="profile" icon={<UserOutlined />}>
          Profile
        </Menu.Item>
      )}
      {/* <Menu.Item key="Info" icon={<CreditCardOutlined />}>
        Card Info
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  //For time formatting
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();

      const formattedDate = now.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      const formattedTime = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      setFormattedDateTime({ formattedDate, formattedTime });
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="mr-4 ">
        <p className="text-[#808080] text-[14px]">
          <span className="mr-2">{formattedDateTime.formattedDate}</span>
          {formattedDateTime.formattedTime}
        </p>
      </div>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className="h-[40px] w-[40px] !bg-[#EEEEEF]"
      >
        <Avatar
          size="large"
          icon={<ProfileIcon />}
          className="h-[32px] w-[32px] cursor-pointer"
        />
      </Dropdown>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={1000}
        centered
      >
        <ProfileForm setIsModalOpen={setIsModalOpen} />
      </Modal>
      {/* <Modal
        open={showCardModal}
        onCancel={() => setShowCardModal(false)}
        footer={null}
        width={1000}
        centered
      >
        <div className="my-6">
          <MyPaymentForm />
        </div>
      </Modal> */}
    </div>
  );
};
