import React, { useState } from "react";
import { Modal, Button } from "antd";

const CommonModal = ({ title, content, isVisible, onClose, onConfirm }) => {
  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={onClose}
      onOk={onConfirm}
      footer={[
        <Button key="back" onClick={onClose} className="hover:!bg-customColor">
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Apply
        </Button>,
      ]}
      width="60%"
      centered
    >
      {content}
    </Modal>
  );
};

export default CommonModal;
