import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Form, Button, Col, Row, Modal } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import AccountIcon from "../../../assets/images/vectors/SideBarIcons/AccountIcon";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { PaymentOrderColumns } from "../Page/Columns";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import { OrderTable } from "./OrderTable";

export const PaymentForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [showModal, setShowModal] = useState(false);
  const [listData, setListData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [accountType, setAccountType] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "OrderNo",
    order: "desc",
  });
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const lisUrl = query
    ? `${process.env.REACT_APP_API_URL}/api/payments/${id}/items?${query}`
    : `${process.env.REACT_APP_API_URL}/api/payments/${id}/items`;

  const {
    data: listInfo,
    loading: listInfoLoading,
    error: listDataError,
  } = useGet(lisUrl);

  const url = id && `${process.env.REACT_APP_API_URL}/api/payments/${id}`;
  const { data, loading, error, refetch } = useGet(url);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setShowModal(true);
        setOrderData(record);
      },
    };
  };

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            DealerName: data.data.DealerName
              ? String(data.data.DealerName)
              : "",
            // StartDate: data.data.StartDate ? String(data.data.StartDate) : "",
            // EndDate: data.data.EndDate ? String(data.data.EndDate) : "",
            Amount: data.data.Amount ? data.data.Amount : "",
            PaymentStatus: data?.data?.PaymentStatus,
            PaymentTime: data.data.PaymentTime
              ? formatUnixTime(data.data.PaymentTime)
              : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  useEffect(() => {
    if (listInfo && !listInfoLoading) {
      const newData = listInfo?.data?.map((el) => ({
        ...el,
        key: el?.OrderNo,
      }));
      setListData(newData);
    }
  }, [listInfo, listInfoLoading]);

  const breadcrumbItems = [
    {
      label: "Payment",
      href: "/paymentlist",
    },
    {
      label: id && "Info",
      href: `/payment/info/${id}`,
    },
  ];

  const buttonsData = [
    {
      label: "Email",
      icon: <MailOutlined />,
      onClick: () => {
        // navigate(`/payment/edit/${id}`);
        console.log("Email sent!");
      },
    },

    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/paymentlist");
      },
    },
  ];

  return (
    <div>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <div className="flex justify-between">
              <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
              <CommonButton buttonsData={buttonsData} />
            </div>
            {
              <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-[#EEEEEF] p-2">
                  <AccountIcon />
                  <span className="text-[16px] text-[#414141] font-semibold ml-2">
                    Payment
                  </span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Dealer"
                        name="DealerName"
                        disabled={true}
                      />
                    </Col>
                    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Start Date"
                        name="StartDate"
                        disabled={true}
                      />
                    </Col> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Amount"
                        name="Amount"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="End Date"
                        name="EndDate"
                        disabled={true}
                      />
                    </Col>
                  </Row> */}
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Payment Status"
                        name="PaymentStatus"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Payment Time"
                        name="PaymentTime"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>
        </Form>
      }
      <div className="mt-6">
        <GlobalTable
          columns={PaymentOrderColumns(setParams, params)}
          dataSource={listData}
          pagination={paginationConfig}
          onRow={onRow}
        />
      </div>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={1000}
        centered
      >
        <OrderTable paymnetId={id} orderData={orderData} />
      </Modal>
    </div>
  );
};
