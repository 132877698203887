import { Button, Col, Input, Select, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SentIcon from "../../../assets/images/vectors/SentIcon";

export const AccountFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
  handleInvitation,
}) => {
  const navigate = useNavigate();
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;

  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedCustomer = getModifiedResponse(optionsData, "customers");
  const modifiedType = getModifiedResponse(optionsData, "roles");
  const modifiedOems = getModifiedResponse(optionsData, "oems");
  const logData = JSON.parse(sessionStorage.getItem("userData"));

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div className="mb-4">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="AccountNo"
              placeholder="No"
              value={filter.AccountNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="UserId"
              placeholder="User ID"
              value={filter.UserId}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="AccountName"
              placeholder="Name"
              value={filter.AccountName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Email"
              placeholder="Email"
              value={filter.Email}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Account Type"
              optionFilterProp="AccountType"
              value={filter.AccountType || undefined}
              onChange={(value) => setFilter({ ...filter, AccountType: value })}
              onSearch={onSearch}
              options={modifiedType}
              style={{ width: "100%" }}
            />
          </Col>
          {![4, 5, 7, 8, 10, 11].includes(logData.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="OEM"
                optionFilterProp="OemNo"
                value={filter.OemNo || undefined}
                onChange={(value) => setFilter({ ...filter, OemNo: value })}
                onSearch={onSearch}
                options={modifiedOems}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {![7, 8, 10, 11].includes(logData.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Dealer"
                optionFilterProp="DealerNo"
                value={filter.DealerNo || undefined}
                onChange={(value) => setFilter({ ...filter, DealerNo: value })}
                onSearch={onSearch}
                options={modifiedDealer}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {![10, 11].includes(logData.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Customer"
                optionFilterProp="CustomerNo"
                value={filter.CustomerNo || undefined}
                onChange={(value) =>
                  setFilter({ ...filter, CustomerNo: value })
                }
                onSearch={onSearch}
                options={modifiedCustomer}
                style={{ width: "100%" }}
              />
            </Col>
          )}
        </Row>
      </div>
      <div className="flex justify-between my-6 overflow-x-auto !items-center">
        <div className="flex items-center">
          <Button
            type="primary"
            className="mr-2 w-[104px] h-[32px] font-medium"
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button className="reset-button" onClick={handleReset}>
            Reset
          </Button>
          <Button
            type="primary"
            className="min-w-[104px] h-[32px] font-medium"
            onClick={handleInvitation}
          >
            <SentIcon />
            Send Invitation
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            className="mr-2 w-[32px] h-[32px]"
            onClick={() => navigate("/account/add")}
          >
            <PlusOutlined />
          </Button>
          <Button
            type="primary"
            disabled={selectedRowKeys?.length === 0 ? true : false}
            className="w-[32px] h-[32px]"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};
