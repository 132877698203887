import {
  faCheckCircle,
  faInfo,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import useDelete from "../../../global/hooks/useDelete";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import CustomCheckbox from "../../../global/components/inputWidgets/CustomCheckBox";
import CustomSwitch from "../../../global/components/inputWidgets/CustomSwitch";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import CommonModal from "../../../global/components/CommonModal";
import { LicenseSelectForm } from "./LicenseSelectForm";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import ConfirmModal from "../../../global/components/ConfirmModal";
import DeviceIcon from "../../../assets/images/vectors/SideBarIcons/DeviceIcon";

export const DeviceForm = ({ type }) => {
  const [showModal, setShowModal] = useState(false);
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id ? `${process.env.REACT_APP_API_URL}/api/device/${id}` : null;
  // const licenseInfoUrl =
  //   selectedRow.length > 0 && selectedRow[0]?.LicenseNo
  //     ? `${process.env.REACT_APP_API_URL}/api/license/${selectedRow[0].LicenseNo}`
  //     : null;
  const licenseInfoUrl =
    selectedRow[0]?.LicenseNo &&
    `${process.env.REACT_APP_API_URL}/api/license/${selectedRow[0]?.LicenseNo}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/device/getoptions`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/device/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/device/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/device/delete/${id}`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: individualLicenseData,
    loading: individualLicenseLoading,
    error: individualLicenseError,
  } = useGet(licenseInfoUrl);
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const modifiedProducts = getModifiedResponse(optionsData, "products");
  const modifiedModels = getModifiedResponse(optionsData, "models");
  const modifiedOems = getModifiedResponse(optionsData, "oems");
  const modifieOp = getModifiedResponse(optionsData, "options");

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction({
          ...values,
          Expired: values.Expired === true ? 1 : 0,
        });
      } else {
        await postAction({
          ...values,
          Expired: values.Expired === true ? 1 : 0,
        });
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    navigate(`/devicelist`);
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (!id || loading) return;
    const getDeviceInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            Mac: data?.data?.Mac,
            Product: data?.data?.Product,
            Model: data?.data?.Model,
            Type: String(data?.data?.Type),
            OemNo: data?.data?.OemNo,
            Options: data?.data?.Options,
            Camera:
              data?.data?.Camera != null ? String(data?.data?.Camera) : "",
            Channel:
              data?.data?.Channel != null ? String(data?.data?.Channel) : "",
            Lockset:
              data?.data?.Lockset != null ? String(data?.data?.Lockset) : "",
            Facegate:
              data?.data?.Facegate != null ? String(data?.data?.Facegate) : "",
            Subnode:
              data?.data?.Subnode != null ? String(data?.data?.Subnode) : "",
            ContLock:
              data?.data?.ContLock != null ? String(data?.data?.ContLock) : "",
            Intercom:
              data?.data?.Intercom != null ? String(data?.data?.Intercom) : "",
            Sicucom:
              data?.data?.Sicucom != null ? String(data?.data?.Sicucom) : "",
            LicenseKey: String(data?.data?.LicenseKey),
            Expired: data?.data?.Expired,
            FirstName: data?.data?.FirstName
              ? String(data?.data?.FirstName)
              : "",
            LastName: data?.data?.LastName ? String(data?.data?.LastName) : "",
            Company: data?.data?.Company ? String(data?.data?.Company) : "",
            Email: data?.data?.Email ? String(data?.data?.Email) : "",
            Phone: data?.data?.Phone ? String(data?.data?.Phone) : "",
            Address: data?.data?.Address ? String(data?.data?.Address) : "",
            InstallType: data?.data?.InstallType
              ? String(data?.data?.InstallType)
              : "",
            Suggestion: data?.data?.Suggestion
              ? String(data?.data?.Suggestion)
              : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getDeviceInfoById();
  }, [id, data, loading, form]);

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  //toast for delete
  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
      navigate("/devicelist");
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    } else {
      return;
    }
  }, [deletedData, deletedDataError]);

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemNo: modifiedOems[0]?.value,
        Product: modifiedProducts[0]?.value,
        Model: modifiedModels[0]?.value,
        Camera: "0",
        Channel: "0",
        Lockset: "0",
        Facegate: "0",
        Subnode: "0",
        ContLock: "0",
        Intercom: "0",
        Sicucom: "0",
      });
    }
  }, [optionsData]);

  const breadcrumbItems = [
    {
      label: "Device",
      href: "/devicelist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/device/info/${id}`
          : `/device/edit/${id}`
        : "/devicelist/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/device/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/devicelist");
            },
          },
        ]
      : [
          {
            label: "License Select",
            icon: <FolderOpenOutlined />,
            onClick: () => {
              setShowCommonModal(true);
            },
          },
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <div>
      <CommonModal
        title="License Select"
        content={
          <LicenseSelectForm
            setSelectedRow={setSelectedRow}
            type="Initial License"
          />
        }
        isVisible={showCommonModal}
        onClose={() => setShowCommonModal(false)}
        onConfirm={() => {
          form.setFieldsValue({
            Options: individualLicenseData?.data?.Options,
            Product: individualLicenseData.data.Product,
            Model: individualLicenseData.data.Model,
            Type: String(individualLicenseData.data.Type),
            OemNo: individualLicenseData.data.OemNo,
            Camera: String(individualLicenseData.data.Camera),
            Channel: String(individualLicenseData.data.Channel),
            Lockset: String(individualLicenseData.data.Lockset),
            Facegate: String(individualLicenseData.data.Facegate),
            Subnode: String(individualLicenseData.data.Subnode),
            ContLock: String(individualLicenseData.data.ContLock),
            Intercom: String(individualLicenseData.data.Intercom),
          });
          setShowCommonModal(false);
        }}
      />
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {!optionDataLoading && (
        <div className="">
          <Form
            validateTrigger={"onChange"}
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            layout={"vertical"}
            autoComplete={"off"}
          >
            <div className="mt-6 overflow-hidden border bg-white rounded-[4px]">
              <div className="flex items-center bg-[#EEEEEF] p-2">
                <DeviceIcon />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  Device
                </span>
              </div>
              <div className="p-2">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="MAC"
                      name="Mac"
                      disable={id || type === "info"}
                      required
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSwitch
                      label="Expired"
                      name="Expired"
                      defaultChecked={0}
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  {type === "info" && (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="License Key"
                        name="LicenseKey"
                        disabled={type === "info"}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </div>
            <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
              <div className="flex items-center bg-[#EEEEEF] p-2">
                <InfoCircleOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  License Information
                </span>
              </div>
              <div className="p-2">
                <Row gutter={[16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Product"
                      name="Product"
                      options={modifiedProducts}
                      placeholder="Please select"
                      disabled={type === "info"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Model"
                      name="Model"
                      options={modifiedModels}
                      placeholder="Please select"
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Type"
                      name="Type"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="OEM"
                      name="OemNo"
                      options={modifiedOems}
                      placeholder="Please select"
                      // value={form.getFieldValue("OemNo")}
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
              <div className="flex items-center bg-[#EEEEEF] p-2">
                <SettingOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  License Options
                </span>
              </div>
              <div className="px-2">
                <Row gutter={[16, 16]} className="p-2">
                  <CustomCheckbox
                    label="Options"
                    name="Options"
                    options={modifieOp}
                    disabled={type === "info"}
                  />
                </Row>
              </div>
            </div>

            <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
              <div className="flex items-center bg-[#EEEEEF] p-2">
                <DesktopOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  License Devices
                </span>
              </div>
              <div className="p-2">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Camera"
                      name="Camera"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Channel"
                      name="Channel"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Lockset"
                      name="Lockset"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Facegate"
                      name="Facegate"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="OSDP Device"
                      name="Subnode"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Trilogy Lockset"
                      name="ContLock"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Intercom"
                      name="Intercom"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Sicunet Intercom"
                      name="Sicucom"
                      type="number"
                      disabled={type === "info"}
                      required
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {type === "info" && (
              <div className="mt-6 bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-[#EEEEEF] p-2">
                  <InfoCircleOutlined className="text-[20px]" />
                  <span className="text-[16px] text-[#414141] font-semibold ml-2">
                    Customer Information
                  </span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="First Name"
                        name="FirstName"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Last Name"
                        name="LastName"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Company"
                        name="Company"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Email"
                        name="Email"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Phone"
                        name="Phone"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Address"
                        name="Address"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Install Type"
                        name="InstallType"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Suggestion"
                        name="Suggestion"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Form>
        </div>
      )}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </div>
  );
};
