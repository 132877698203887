import SortIcon from "../../../global/components/SortIcon";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";

export const PaymentColumns = (setParams, params, hideBak) => {
  const logData = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "PaymentNo",
      key: "PaymentNo",
      sorter: (a, b) => a?.PaymentNo - b?.PaymentNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="PaymentNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "PaymentNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemName",
      key: "OemName",
      hidden: [4, 5, 6, 7, 8, 9, 10, 11, 12].includes(logData.role_value),
      sorter: (a, b) => a?.OemName - b?.OemName,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerName",
      hidden: [7, 8, 9].includes(logData.role_value),
      key: "DealerName",
      sorter: (a, b) => a?.DealerName?.length - b?.DealerName?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "StartDate",
    //   key: "StartDate",
    //   // render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
    //   sorter: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
    //   sortIcon: () => (
    //     <SortIcon
    //       sortOrder={params.order}
    //       columnKey="StartDate"
    //       sortBy={params.sort_by}
    //     />
    //   ),
    //   onHeaderCell: () => ({
    //     onClick: () =>
    //       setParams((prev) => ({
    //         ...prev,
    //         sort_by: "StartDate",
    //         order: prev.order === "desc" ? "asc" : "desc",
    //       })),
    //   }),
    // },

    // {
    //   title: "End Date",
    //   dataIndex: "EndDate",
    //   key: "EndDate",
    //   // render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
    //   sorter: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),
    //   sortIcon: () => (
    //     <SortIcon
    //       sortOrder={params.order}
    //       columnKey="EndDate"
    //       sortBy={params.sort_by}
    //     />
    //   ),
    //   onHeaderCell: () => ({
    //     onClick: () =>
    //       setParams((prev) => ({
    //         ...prev,
    //         sort_by: "EndDate",
    //         order: prev.order === "desc" ? "asc" : "desc",
    //       })),
    //   }),
    // },

    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => {
        return `$ ${text}`;
      },
      sorter: (a, b) => a?.Amount?.length - b?.Amount?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Amount"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Amount",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Payment Status",
      dataIndex: "PaymentStatus",
      key: "PaymentStatus",
      sorter: (a, b) => a?.PaymentStatus?.length - b?.PaymentStatus?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="PaymentStatus"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "PaymentStatus",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Card Number",
      dataIndex: "CardLast4",
      key: "CardLast4",
      render: (text) => <div>******** ****{text}</div>,
      sorter: (a, b) => a?.CardLast4?.length - b?.CardLast4?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CardLast4"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CardLast4",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },

    {
      title: "Payment Time",
      dataIndex: "PaymentTime",
      key: "PaymentTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.PaymentTime) - new Date(b.PaymentTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="PaymentTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "PaymentTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
  ];
};

export const PaymentOrderColumns = (setParams, params) => {
  return [
    {
      title: "Order No",
      dataIndex: "OrderNo",
      key: "OrderNo",
      sorter: (a, b) => a?.OrderNo - b?.OrderNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Name",
      dataIndex: "OrderName",
      // hidden: hideBak,
      key: "OrderName",
      sorter: (a, b) => a?.OrderName?.length - b?.OrderName?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Time",
      dataIndex: "OrderTime",
      key: "OrderTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.OrderTime) - new Date(b.OrderTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },

    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => {
        return `$ ${text}`;
      },
      sorter: (a, b) => a?.Amount?.length - b?.Amount?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Amount"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Amount",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
  ];
};

export const OrderItemColumns = (setParams, params) => {
  return [
    {
      title: "Item No",
      dataIndex: "ItemNo",
      key: "ItemNo",
      sorter: (a, b) => a?.ItemNo - b?.ItemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="ItemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "ItemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Customer",
      dataIndex: "CustomerName",
      // hidden: hideBak,
      key: "CustomerName",
      sorter: (a, b) => a?.CustomerName?.length - b?.CustomerName?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License",
      dataIndex: "LicenseName",
      key: "LicenseName",
      sorter: (a, b) => a?.LicenseName?.length - b?.LicenseName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      render: (text) => {
        return `$ ${text}`;
      },
      sorter: (a, b) => a?.Price?.length - b?.Price?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Price"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Price",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
  ];
};
