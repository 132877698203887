export const allProtectedRouteList = {
  1: [
    "/profile",
    "/oemlist",
    "/oem/add",
    "devicelist/add",
    "/devicelog/info/",
    "/device/edit",
    "/device/info",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/devicelist",
    "/deviceloglist",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/licenselist",
    "/license/add",
    "/license/edit",
    "/license/info",
    "/licenseoptionlist",
    "/licenseoption/add",
    "/licenseoption/info",
    "/licenseoption/edit",
    "/licenseoption",
    "/oeminfolist",
    "/oeminfo/add",
    "/oem/edit",
    "/oem/info",
    "/oeminfo/edit",
    "/oeminfo/info",
    "/addDeviceByList",
    "/addDeviceByCount",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  2: [
    "/profile",
    "/oemlist",
    "/oem/add",
    "devicelist/add",
    "/devicelog/info/",
    "/device/edit",
    "/device/info",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/devicelist",
    "/deviceloglist",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/licenselist",
    "/license/add",
    "/license/edit",
    "/license/info",
    "/licenseoptionlist",
    "/licenseoption/add",
    "/licenseoption/info",
    "/licenseoption/edit",
    "/licenseoption",
    "/oeminfolist",
    "/oeminfo/add",
    "/oem/edit",
    "/oem/info",
    "/oeminfo/edit",
    "/oeminfo/info",
    "/addDeviceByList",
    "/addDeviceByCount",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  3: [
    "/profile",
    "/oemlist",
    "/oem/add",
    "devicelist/add",
    "/devicelog/info/",
    "/device/edit",
    "/device/info",
    "/devicelist",
    "/deviceloglist",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/licenselist",
    "/license/add",
    "/license/edit",
    "/license/info",
    "/licenseoptionlist",
    "/licenseoption/add",
    "/licenseoption/info",
    "/licenseoption/edit",
    "/licenseoption",
    "/addDeviceByList",
    "/addDeviceByCount",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  4: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  5: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  6: [
    "/profile",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  7: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  8: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/customerlist",
    "/customer/add",
    "/customer/edit",
    "/customer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  9: [
    "/profile",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/paymentlist",
    "/payment/info",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  10: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  11: [
    "/profile",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  12: [
    "/profile",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/OrderIcon",
    "/orderitem/edit",
    "/orderitem/info",
  ],
};
