import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { PaymentColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import { AccountFilter } from "../Components/FilterComponent";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { Modal } from "antd";

const initialFilterState = {
  PaymentNo: "",
  DealerNo: "",
  StartDate: "",
  EndDate: "",
  PaymentStatus: "",
  PaymentTime: "",
};

export const Payment = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filter, setFilter] = useState(initialFilterState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const logData = JSON.parse(sessionStorage.getItem("userData"));
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "PaymentNo",
    order: "desc",
  });

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "Payment",
      href: "/paymentlist",
    },
  ];

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/payments/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/payments/all`;
  const { data, loading, error, refetch } = useGet(url);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys, newSelectedRow) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  //   fixed: true,
  // };

  const onRow = (record) => {
    return {
      onClick: (event) => {
        navigate(`/payment/info/${record?.PaymentNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "PaymentNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  //sent invitation
  const handleInvitation = (e) => {
    setIsModalOpen(true);
  };
  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);
    const params = {
      limit: 10,
      sort_by: "PaymentNo",
      order: "desc",
    };
    setParams(params);
  };

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.PaymentNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-[4px] border border-gray-200">
        <AccountFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleFilter={handleFilter}
          handleReset={handleReset}
          setShowModal={setShowModal}
          selectedRowKeys={selectedRowKeys}
          setFilter={setFilter}
          handleInvitation={handleInvitation}
        />
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={PaymentColumns(
            setParams,
            params,
            logData.role_value === 1 || logData.role_value === 3,
            logData.role_value === 3
          )}
          dataSource={listData?.filter(
            (item) => item.UserId !== logData.user_id
          )}
          // rowSelection={rowSelection}
          pagination={paginationConfig}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
        />
      </div>
    </div>
  );
};
