import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import { getModifiedResponse } from "../utils/responseModifier";
import useGet from "../hooks/useGet";
import { showToast } from "../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "./CommonBreadcrumb";
import CommonButton from "./CommonButton";
import CustomInput from "./inputWidgets/CustomInput";
import usePut from "../hooks/usePut";
import {
  UnorderedListOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import CustomSwitch from "./inputWidgets/CustomSwitch";
import AccountIcon from "../../assets/images/vectors/SideBarIcons/AccountIcon";

export const ProfileForm = ({ setIsModalOpen }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loggedInUserData = JSON.parse(sessionStorage.getItem("userData"));
  const id = loggedInUserData.account_no;

  const url = `${process.env.REACT_APP_API_URL}/api/account/${id}`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/account/update/${id}`;

  const { data, loading, error } = useGet(url);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = (values) => {
    try {
      putAction(values);
      setIsModalOpen(false);
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  //toast for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
    }
  }, [putData, putLoading, putError]);

  const onReset = () => {
    // form.resetFields();
    // navigate("/accountlist");
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      console.log(data.data);
      try {
        if (data?.data) {
          form.setFieldsValue({
            AccountNo: String(data?.data?.AccountNo),
            UserId: String(data?.data?.UserId),
            AccountName: String(data.data.AccountName),
            TwoFactor: data.data.TwoFactor ? 1 : 0,
            AccountDesc: data.data.AccountDesc
              ? String(data.data.AccountDesc)
              : "",
            Email: data.data.Email ? String(data.data.Email) : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Account",
      href: "/accountlist",
    },
    {
      label: "Add",
      href: "account/add",
    },
  ];

  const buttonsData = [
    // {
    //   label: "List",
    //   icon: <UnorderedListOutlined />,
    //   onClick: () => {
    //     navigate("/accountlist");
    //   },
    // },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    !loading && (
      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
      >
        <div className="mt-8 bg-white rounded-[4px] overflow-hidden">
          <div className="flex items-center bg-[#EEEEEF] p-2">
            <AccountIcon />
            <span className="text-[16px] text-[#414141] font-semibold ml-2">
              Profile
            </span>
          </div>
          {!loading && (
            <div className="mt-4 px-2">
              <Row gutter={[16, 16]}>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Account No" name="AccountNo" />
                </Col> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="User Name" name="UserId" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Password"
                    name="password"
                    type="password"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Name" name="AccountName" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Description" name="AccountDesc" />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Email" name="Email" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSwitch
                    label="Two Factor Authentication"
                    name="TwoFactor"
                    defaultChecked={0}
                  />
                </Col>
              </Row>
            </div>
          )}
          <div className="flex justify-end">
            <CommonButton buttonsData={buttonsData} />
          </div>
        </div>
      </Form>
    )
  );
};
